import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { updatePassword } from "../../api/update";
import Input from "../../components/forms/input";
import Error from "../../components/error-message";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { toast } from "react-toastify";

const ConfirmPassword = ({ userDetails, openPopup, setOpenPopup, setToastMessage, setToastSuccess, setShowToast }) => {
  const [loader, setLoader] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: yup.object({
      new_password: yup
        .string()
        .required("New password is required")
        .min(8, "Password must be at least 8 characters")
        .max(16, "Password must be less then 16 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(
          /[0-9]/,
          "Password must contain at least one numeric character"
        )
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .test(
          "not-same-as-old",
          "New password must be different from old password",
          function (value) {
            return value !== this.parent.old_password;
          }
        ),
      confirm_password: yup
        .string()
        .required("Confirm password is required")
        .oneOf(
          [yup.ref("new_password")],
          "Confirm password must match with new password"
        ),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      await updatePassword(values, userDetails?.id).then(async (res) => {
        if (!res?.detail) {
          setShowNewPassword(false);
          setShowConfirmPassword(false);
          setOpenPopup(false);
          // localStorage.setItem("userProfile", "true");
          formik.resetForm();
          // toast.success(res?.message);
          setToastMessage(res?.message);
          setToastSuccess(res?.success);
          setShowToast(true);
          setLoader(false);
          // setTimeout(() => {
          //   window.location.href = "/dashboard";
          // }, 3000);
        } else {
          setShowNewPassword(false);
          setShowConfirmPassword(false);
          // toast.error(res?.detail?.message || "Check your password");
          setToastMessage(res?.detail?.message || "Check your password");
          setToastSuccess(res?.success);
          setShowToast(true);
          setLoader(false);
        }
      });
    },
  });


  return (
    <div>
      {" "}
      <Modal
        show={openPopup}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body onClick={(e) => e.stopPropagation()}>
          <form
            className="p-3"
            onSubmit={formik.handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                formik.handleSubmit();
              }
            }}
          >
            <div>
              <h3
                className="text-center"
                style={{ fontSize: "24px", fontWeight: "600" }}
              >
                Reset Password
              </h3>
              <p
                className="admin-para text-center"
                style={{ color: "#778399", fontSize: "15px" }}
              >
                Your temporary password is valid for 15 days.
                <br />
                Please change your password.
              </p>
            </div>
            <div className="row form-fields-control">
              <div className="col-12">
                <label className="label-name" style={{ fontSize: "14px" }}>
                  New Password
                  <sup
                    className="sup-symbol position-relative"
                    style={{ top: "-4px" }}
                  >
                    *
                  </sup>
                </label>
                <div className="position-relative">
                  <Input
                    position="relative"
                    max={16}
                    name="new_password"
                    placeholder="New Password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type={showNewPassword ? "text" : "password"}
                    error={
                      formik.errors.new_password &&
                      formik?.touched?.new_password
                    }
                  />
                  {showNewPassword ? (
                    <BsEye
                      className="cursor-pointer eyeSlash-icon-1"
                      style={{
                        position: "absolute",
                        right: "7px",
                        top: "10px",
                      }}
                      size={20}
                      onClick={() => setShowNewPassword(false)}
                    />
                  ) : (
                    <BsEyeSlash
                      className="cursor-pointer eyeSlash-icon-1"
                      style={{
                        position: "absolute",
                        right: "7px",
                        top: "10px",
                      }}
                      size={20}
                      onClick={() => setShowNewPassword(true)}
                    />
                  )}
                  <div className="error-space mb-1">
                    <Error
                      error={
                        formik.errors.new_password &&
                          formik?.touched?.new_password
                          ? formik?.errors.new_password
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-1 form-fields-control">
              <div className="col-12">
                <label className="label-name" style={{ fontSize: "14px" }}>
                  Confirm Password
                  <sup
                    className="sup-symbol position-relative"
                    style={{ top: "-4px" }}
                  >
                    *
                  </sup>
                </label>
                <div className="position-relative">
                  <Input
                    position="relative"
                    max={16}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type={showConfirmPassword ? "text" : "password"}
                    error={
                      formik.errors.confirm_password &&
                      formik?.touched?.confirm_password
                    }
                  />
                  {showConfirmPassword ? (
                    <BsEye
                      className="cursor-pointer eyeSlash-icon-1"
                      size={20}
                      style={{
                        position: "absolute",
                        right: "7px",
                        top: "10px",
                      }}
                      onClick={() => setShowConfirmPassword(false)}
                    />
                  ) : (
                    <BsEyeSlash
                      className="cursor-pointer eyeSlash-icon-1"
                      size={20}
                      style={{
                        position: "absolute",
                        right: "7px",
                        top: "10px",
                      }}
                      onClick={() => setShowConfirmPassword(true)}
                    />
                  )}
                  <div className="error-space mb-1">
                    <Error
                      error={
                        formik.errors.confirm_password &&
                          formik?.touched?.confirm_password
                          ? formik?.errors.confirm_password
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              disabled={
                loader ||
                formik.values.new_password === "" ||
                formik.values.new_password !== formik.values.confirm_password
              }
              style={{
                borderRadius: "8px",
                width: "100%",
                backgroundColor: "#0F337C",
                color: "#FFFFFF",
                marginTop: "5px",
                cursor:
                  loader ||
                    formik.values.new_password === "" ||
                    formik.values.new_password !== formik.values.confirm_password
                    ? "not-allowed"
                    : "pointer",
                opacity:
                  loader ||
                    formik.values.new_password === "" ||
                    formik.values.new_password !== formik.values.confirm_password
                    ? "0.8"
                    : "1",
              }}
              type="submit"
            // onClick={handleClick}
            >
              Reset my Password
            </Button>{" "}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmPassword;
