/** ****************************** Import libs *********************************** */

const URL_CONSTANTS = {
  // ------------------------------- Account Settings User and Admin End points -----------------------------
  team: "leadq-admin/create_user",
  login: "leadq-admin/login_user",
  getAllAdmin: "leadq-admin/get_all_admin_user",
  getAdmin: "leadq-admin/get_all_team_members",
  admin: "leadq-admin/update_user_details",
  updateTeam: "leadq-admin/update_user_details",
  deleteUser: "leadq-admin/delete_user",
  updatePassword: "leadq-admin/update_password",
  deleteHistory: "leadq-admin/get_all_deleted_team_members",
  updateActiveStatus: "leadq-admin/user_active_status",
  getColdDueDate: "leadq-admin/get_cold_reach_pending_duration",
  updateDueDate: "leadq-admin/cold_reach_pending_duration",
  getQuarterlyCalendar: "leadq-admin/get_quarterly_calender",
  updateQuarterlyCalendar: "leadq-admin/update_quarterly_calender",
  emailNotification: "leadq-admin/get_email_notification_settings",
  updateEmailNotification: "leadq-admin/update_email_notification_settings",
  getManagers: "leadq-admin/get_all_manager",
  // -------------------------------- Roles & Access End points -----------------------------------
  rolesPost: "leadq-admin/create_role_permission",
  roles: "leadq-admin/get_all_roles",
  rolesPut: "leadq-admin/update_role_permission",
  get_user_role: "leadq-admin/get_role_permission",
  getAnalytics: "leadq-admin/reports_analytics",
  // -------------------------------- Master Tables End points -------------------------------------
  getDesignation: "leadq-admin/get_all_designation",
  getLead: "leadq-admin/get_all_lead",
  getClients: "leadq-admin/get_all_clients",
  createLead: "leadq-admin/create_lead",
  createLeadOrg: "leadq-admin/create_leads_and_org",
  patchDeleteLead: "leadq-admin/batch_delete_lead",
  deleteLead: "leadq-admin/delete_lead",
  updateLead: "leadq-admin/update_leads_details",
  updateClient: "leadq-admin/update_client_details",
  activityLead: "leadq-admin/get_all_activity",
  activityDeal: "leadq-admin/get_all_deals_activity",
  activityClient: "leadq-admin/get_all_activity",
  meetingActivity: "leadq-admin",
  getSuccessLeads: "leadq-admin/get_success_leads",
  getOrg: "leadq-admin/get_all_org",
  getIndustry: "leadq-admin/get_all_industry",
  getJobTitle: "leadq-admin/get_all_job_title",
  createOrg: "leadq-admin/create_org",
  deleteOrg: "leadq-admin/delete_org",
  batchDeleteOrg: "leadq-admin/batch_delete_org",
  createMeeting: "leadq-admin/create_meeting_activity",
  getMeeting: "leadq-admin/get_meeting_activity",
  updateMeeting: "leadq-admin/update_meeting_activity",
  updateOrg: "leadq-admin/update_org",
  updateSummary: "leadq-admin/update_meeting_summary",
  updateStatus: "leadq-admin/update_meeting_status",
  deleteMeeting: "leadq-admin/delete_meeting_activity",
  deleteMail: "leadq-admin/delete_mail_activity",
  deleteDeal: "leadq-admin/delete_deal",
  getLeadReview: "leadq-admin/get_reviews",
  getClientReview: "leadq-admin/get_reviews",
  mailCreate: "leadq-admin/create_mail_activity",
  updateMail: "leadq-admin/update_mail_activity",
  getMail: "leadq-admin/get_mail_activity",
  getDeal: "leadq-admin/get_all_deal",
  getDealActivity: "leadq-admin/get_deal_activity",
  createDeal: "leadq-admin/create_deal",
  dealType: "leadq-admin/get_all_deal_type",
  dealService: "leadq-admin/get_all_deal_service",
  updateDealStatus: "leadq-admin/update_deal_status",
  updateDeal: "leadq-admin/update_deal",
  updateDealClosed: "leadq-admin/update_deal_closed_status",
  getImport: "leadq-admin/get_all_import_details",
  getExport: "leadq-admin/get_contact_sheet",
  uploadFile: "leadq-admin/create_import_records",
  postImport: "leadq-admin/upload_file",
  getRegion: "leadq-admin/get_all_region",
  getCountry: "leadq-admin/get_country",
  getAllTimezone:"leadq-admin/all_timezone",
  getAllCountry: "leadq-admin/get_all_country",
  getTimeZone: "leadq-admin/get_country_timezone",
  updateReview: "leadq-admin/create_review",
  dealCategory: "leadq-admin/get_all_deal_category",
  dealCategoryById: "leadq-admin/get_deal_category",
  getCompanySize: "leadq-admin/get_all_company_size",
  getRevenue: "leadq-admin/get_all_revenue_range",
  dealTimeLine: "leadq-admin/get_all_deals_timeline",
  dealTicketSize: "leadq-admin/get_all_deals_ticket_size",
  getDealById: "leadq-admin/get_deal_by_id",
  coldReachGet: "leadq-admin/get_all_cold_reach",
  coldReachGetActivity: "leadq-admin/create_cold_reach_activity",
  callBackLeads: "leadq-admin/get_call_back_leads",
  createColdReach: "leadq-admin/create_cold_reach_lead",
  createMultiPleColdReach: "leadq-admin/create_multiple_cold_reach_leads",
  updateReAssignColdReach: "leadq-admin/re-assign_lead",
  multiReAssignColdReach: "leadq-admin/multiple_re-assign_lead",
  coldReachById: "leadq-admin/get_cold_lead",
  putColdLeadActivity: "leadq-admin/create_cold_reach_activity",
  updateStatusCold: "leadq-admin/update_move_to_lead_status",
  dashboard: "leadq-admin/get_all_dashboard_details",
  dashboarCount: "leadq-admin/get_modules_based_count",
  dashboarBarLG: "leadq-admin/get_bar_chart_data",
  dashboarSourceLG: "leadq-admin/get_donut_chart_data",
  dashboarColdReachLG: "leadq-admin/get_pie_chart_data",
  dashboardUpcomingMeetings: "leadq-admin/get_upcoming_meeting",
  callback: "leadq-admin/get_call_back_leads",
  getLeadById: "leadq-admin/get_lead",
  getOrganizationById: "leadq-admin/get_org",
  getLeadScoreById: "leadq-admin/get_all_reviews",
  updateLeadScoreById: "leadq-admin/update_lead_review",
  notification: "leadq-admin/get_all_notification",
  notificationUpdateById: "leadq-admin/update_read_status",
  notificationBulkUpdate: "leadq-admin/update_mark_all_as_read_status",
  updateEmailVerify: "leadq-admin/update_email_verification",
  updateNoVerify: "leadq-admin/update_no_verification",
  getCountryState: "leadq-admin/get_country_state_timezone",
  updateCallBack: "leadq-admin/update_call_back_view_leads",
  coldReachLog: "leadq-admin/get_all_cold_reach_activity",
  //contact New
  contacts: "leadq-admin/create_contact",
  getContacts: "leadq-admin/get_all_contacts",
  getContactsById: "leadq-admin/get_contact",
  getClientId: "leadq-admin/get_client",
  UpdateContacts: "leadq-admin/update_contact",
  getAllAdminAssign: "leadq-admin/get_all_users_assignee",
  createMultiAssign: "leadq-admin/assign_multiple_cold_reach",
  createSingleAssign: "leadq-admin/assign_cold_reach",
  addToken: "leadq-admin/add_notification_token",
  createMultiReAssign: "leadq-admin/multiple_re_assign_cold_reach",
  createSingleReAssign: "leadq-admin/re_assign_cold_reach",
  myTaskDetails: "leadq-admin/get_all_cold_reach_details",
  myTaskInfo: "leadq-admin/get_cold_lead",
  myTaskActivityRecent: "leadq-admin/get_cold_reach_activity",
  DeleteContact: "leadq-admin/delete_contact",
  BatchDeleteContact: "leadq-admin/delete_batch_contact",
  createContactLead: "leadq-admin/create_contact_and_org",
  getDroppedContact: "leadq-admin/get_dropped_contacts",
  getDroppedInfo: "leadq-admin/get_cold_lead",
  getmyTaskActiveUser: "leadq-admin/get_cold_reach_members",
  droppedDel: "leadq-admin/delete_dropped_contact",
  droppedBatchDel: "leadq-admin/delete_batch_dropped_contact",
  // -------------------------------- Prospects -----------------------------//
  getProspects: "leadq-admin/get_all_prospects",
  getProspectId: "leadq-admin/get_prospect",
  updateProspects: "leadq-admin/update_prospect",
  deleteProspects: "leadq-admin/delete_prospect",
  ProspectsMove: "leadq-admin/prospect_move",
  BatchProspectsMove: "leadq-admin/prospect_batch_move",
  BatchProspectDelete: "leadq-admin/prospect_batch_delete",
  ProspectsSingleAssign: "leadq-admin/assign_prospect",
  ProspectsMultiAssign: "leadq-admin/prospect_batch_assign",
  // ----------------------------------- Leads --------------------------
  createSignleLeadAssign: "leadq-admin/re_assign_lead",
  createMultiLeadReAssign: "leadq-admin/batch_re_assign_lead",
  LeadsSignleMove: "leadq-admin/move_to_cold_reach_lead",
  LeadsMultiMove: "leadq-admin/batch_move_to_cold_reach_lead",
  deleteSingleLeads: "leadq-admin/delete_lead",
  deleteSingleClient: "leadq-admin/delete_client",
  deleteMultiLeads: "leadq-admin/batch_delete_lead",
  deleteMultiClients: "leadq-admin/batch_delete_client",
  leadsInfo: "leadq-admin/get_lead",
  clientsInfo: "leadq-admin/get_client",
  getStaticDataImport: "leadq-admin/get_static_details",
  deleteStaticDataImport: "leadq-admin/remove_cancel_import_file",
  prospectsColdReach: "leadq-admin/prospect/get_cold_reach_activity",
  getContactHistory: "leadq-admin/history",
  // -------------------------------------- Analytics --------------------------
  getAnalyticsTotal: "leadq-admin/total_progress_report",
  getAnalyticsColdReach: "leadq-admin/cold_reach_statistics",
  getAnalyticsProspect: "leadq-admin/prospect_statistics",
  getAnalyticsLeads: "leadq-admin/lead_statistics",
  getAnalyticsDeals: "leadq-admin/deal_statistics",
  getAnalyticsContacts: "leadq-admin/contacts_statistics",
  getAnalyticsContactsProgress: "leadq-admin/contacts_executive_progress_bar",
  getNewAnalyticsTotalCount: "leadq-admin/get_analytics_based_count",
  getAnalyticsProspectProgress: "leadq-admin/prospect_executive_progress_bar",
  getAnalyticsLeadsProgress: "leadq-admin/lead_executive_progress_bar",
  getAnalyticsColdReachProgress:
    "leadq-admin/cold_reach_executive_progress_bar",
  getAnalyticsDealProgress: "leadq-admin/deal_executive_progress_bar",
};

export { URL_CONSTANTS };
