// Here we will create dark and light theme variables

// themes.js
export const lightTheme = {
  body: "#FFFFFF",
  text: "#000000",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "252, 246, 244",
  textRgba: "0,0,0",
  background: "#FFFFFF",
  transition: "all 0.5s ease",
  subMenuBG: "#ffffff",
};

export const DealslightTheme = {
  body: "#F0F0F0",
  text: "#000000",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "252, 246, 244",
  textRgba: "0,0,0",
  background: "#F0F0F0",
  transition: "all 0.5s ease",
  subMenuBG: "#ffffff",
};
export const DarkThemeSidebar = {
  body: "#000",
  text: "#FCF6F4",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "0,0,0",
  textRgba: "252, 246, 244",
  background: "#000000",
  transition: "all 0.5s ease",
};

export const DarkTheme = {
  body: "#333",
  text: "#FCF6F4",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "0,0,0",
  textRgba: "252, 246, 244",
  background: "#000000",
  transition: "all 0.5s ease",
  subMenuBG: "#333",
};

export const DarkThemeAccountSetting = {
  body: "#191919",
  text: "#FCF6F4",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "0,0,0",
  textRgba: "252, 246, 244",
  background: "#191919",
  transition: "all 0.5s ease",
};

export const DarkThemeDeals = {
  body: "#212121",
  text: "#FCF6F4",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "0,0,0",
  textRgba: "252, 246, 244",
  background: "#2F2F2F",
  transition: "all 0.5s ease",
};

const theme = {
  colors: {
    plum: "#52314b",
    rose: "#c3537d",
    dryrose: "#dd7f9a",
    primrose: "#e8babf",
    white: "#fff",
    dark: "black",
    gray: "#3c4b64",
    GrayTop: "#303c54",
  },
};

export default theme;
